import { gql } from '../../../__generated__';

gql(`
fragment PersonFields on Person {
  id
  email
  personType
}

fragment SessionFields on Session {
    id
    patientId
    surgeonName
    procedure
    start
    end
    url
    dailyToken
    dailyRoomId
    dailyRoomName

    room {
      id
      name
      facility {
        id
        name
      }
    }

    invitees {
      ...PersonFields
    }

    activeRoomParticipants {
      ...PersonFields
    }

    activeRoomParticipantsCount
}
`);

export const GET_SESSIONS = gql(`
query GetSessions($roomId: String!, $first: Int, $after: String) {
  getSessions(roomId: $roomId, first: $first, after: $after) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...SessionFields
        }
      }
  }
}`);
