import { useMutation, useQuery } from '@apollo/client';
import { FunctionComponent } from 'react';
import { DELETE_FACILITY, GET_FACILITIES, UPSERT_FACILITY } from './queries';
import { FacilityConnection } from '../../__generated__/graphql';
import DeleteButton from '../../components/DeleteButton';
import UpsertFacilityButton from './UpsertFacilityButton';

import FacilityTable from '../../components/FacilityTable';
import GraphQLErrors from '../../components/GraphQLErrors';

const FacilityPage: FunctionComponent = () => {
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_FACILITIES);
  const [deleteFacility] = useMutation(DELETE_FACILITY);
  const [upsertFacility] = useMutation(UPSERT_FACILITY);

  const handleLoadMore = () => {
    if (data)
      fetchMore({
        variables: {
          after: data.getFacilities.pageInfo.endCursor,
        },
      });
  };

  if (loading) return <h1>Loading...</h1>;
  if (error) return <GraphQLErrors error={error} />;
  if (!data) return <h1>No data</h1>;

  return (
    <FacilityTable
      data={data.getFacilities as FacilityConnection}
      refetch={refetch}
      handleLoadMore={handleLoadMore}
      headerButtons={[
        <UpsertFacilityButton
          handleSave={(data) =>
            upsertFacility({ variables: { input: data } }).then(() => refetch())
          }
          key="add"
        />,
      ]}
      facilityButtons={[
        (node) => (
          <UpsertFacilityButton
            handleSave={(data) =>
              upsertFacility({ variables: { input: data } }).then(() =>
                refetch(),
              )
            }
            editFields={node}
            key="edit"
          />
        ),
        (node) => (
          <DeleteButton
            buttonSize="sm"
            handleDelete={() =>
              deleteFacility({ variables: { id: node.id } }).then(() =>
                refetch(),
              )
            }
            key="delete"
          />
        ),
      ]}
    />
  );
};

export default FacilityPage;
