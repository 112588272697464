import { useQuery } from "@apollo/client";
import { FunctionComponent } from "react";
import { Badge, Button, ButtonGroup, Card, Table } from "react-bootstrap";
import { ArrowClockwise, ChevronDoubleDown } from "react-bootstrap-icons";
import { useParams, useSearchParams } from "react-router-dom";
import { SessionFieldsFragment } from "../../../__generated__/graphql";
import GraphQLErrors from "../../../components/GraphQLErrors";
import { GET_SESSIONS } from "./queries";

const SessionsPage: FunctionComponent = () => {
    const { roomId } = useParams<{ roomId: string }>();
    const [searchParams] = useSearchParams();
    const { data, error, loading, refetch, fetchMore } = useQuery(GET_SESSIONS, { variables: { roomId: roomId! } });

    const handleLoadMore = () => {
        if (data)
            fetchMore({
                variables: {
                    roomId: roomId,
                    after: data.getSessions.pageInfo.endCursor,
                },
            });
    };

    if (loading) return <h1>Loading...</h1>;
    if (error) return <GraphQLErrors error={error} />;
    if (!data) return <h1>No data</h1>;

    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    Sessions for {searchParams.get("room") ?? "Unknown" } <Badge pill>{data.getSessions.totalCount}</Badge>{' '}
                    <ButtonGroup size="sm">
                        <Button onClick={() => refetch()} variant="outline-secondary">
                            <ArrowClockwise />
                        </Button>
                    </ButtonGroup>
                </Card.Title>
                <Table hover size="sm">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Start/End</th>
                            <th>Daily Room Name</th>
                            <th>Daily URL</th>
                            <th>Particpants Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.getSessions.edges.map((e) => {
                            const node = e.node as SessionFieldsFragment;
                            return (
                                <tr key={node.id}>
                                    <td>{node.id}</td>
                                    <td>
                                        {node.start} - {node.end}
                                    </td>
                                    <td>
                                        {node.dailyRoomName ?? "N/A"}
                                    </td>
                                    <td>
                                        {node.url ?? "N/A"}
                                    </td>
                                    <td>
                                        {node.activeRoomParticipantsCount}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                <Button
                    variant="outline-secondary"
                    disabled={!data.getSessions.pageInfo.hasNextPage}
                    onClick={handleLoadMore}
                >
                    Load More <ChevronDoubleDown />
                </Button>
            </Card.Body>
        </Card>
    );
};

export default SessionsPage;