import { useMutation, useQuery } from '@apollo/client';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { GET_PERSON_FACILITIES, UPDATE_PERSON_FACILITY } from './queries';
import GraphQLErrors from '../../components/GraphQLErrors';
import FacilityTable from '../../components/FacilityTable';
import { ActionEnum, FacilityConnection } from '../../__generated__/graphql';
import AddFacilityButton from './AddFacilityButton';

import Button from 'react-bootstrap/Button';
import { Dash } from 'react-bootstrap-icons';

const PersonDetails: FunctionComponent = () => {
  const { personId } = useParams<{ personId: string }>();
  const { data, error, loading, refetch, fetchMore } = useQuery(
    GET_PERSON_FACILITIES,
    {
      variables: { personId: personId! },
    },
  );
  const [updatePersonFacility] = useMutation(UPDATE_PERSON_FACILITY);

  const handleLoadMore = () => {
    if (data)
      fetchMore({
        variables: { after: data.getPerson.facilities.pageInfo.endCursor },
      });
  };

  const handleAddFacility = (facilityId: string) => {
    updatePersonFacility({
      variables: { action: ActionEnum.Add, facilityId, personId: personId! },
    }).then(() => refetch());
  };
  const handleRemoveFacility = (facilityId: string) => {
    updatePersonFacility({
      variables: { action: ActionEnum.Remove, facilityId, personId: personId! },
    }).then(() => refetch());
  };

  if (loading) return <h1>Loading...</h1>;
  if (error) return <GraphQLErrors error={error} />;
  if (!data) return <h1>No data</h1>;

  return (
    <FacilityTable
      data={data.getPerson.facilities as FacilityConnection}
      refetch={refetch}
      handleLoadMore={handleLoadMore}
      headerButtons={[
        <AddFacilityButton handleAdd={handleAddFacility} key="add" />,
      ]}
      facilityButtons={[
        (node) => (
          <Button
            variant="danger"
            onClick={() => handleRemoveFacility(node.id)}
            key="remove"
          >
            <Dash />
          </Button>
        ),
      ]}
    />
  );
};

export default PersonDetails;
