import { ApolloError } from '@apollo/client';
import { FunctionComponent } from 'react';

import Alert from 'react-bootstrap/Alert';

const GraphQLErrors: FunctionComponent<{ error: ApolloError }> = (props) => {
  return (
    <>
      <h1>Error</h1>
      <Alert variant="danger">{props.error.message}</Alert>
    </>
  );
};

export default GraphQLErrors;
