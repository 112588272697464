import { FunctionComponent, useState } from 'react';

import Button, { ButtonProps } from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { Trash } from 'react-bootstrap-icons';

interface Props {
  buttonSize?: ButtonProps['size'];
  handleDelete: () => any | Promise<any>;
}

const DeleteButton: FunctionComponent<Props> = (props) => {
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleConfirm = () => {
    setDeleting(true);
    Promise.resolve(props.handleDelete()).finally(() => {
      setDeleting(false);
      handleClose();
    });
  };

  return (
    <>
      <Button variant="danger" size={props.buttonSize} onClick={handleShow}>
        <Trash />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={deleting}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirm} disabled={deleting}>
            {deleting ? 'Deleting...' : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteButton;
