import { FunctionComponent } from 'react';
import {
  FacilityConnection,
  FacilityFieldsFragment,
} from '../__generated__/graphql';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

import { ArrowClockwise, ChevronDoubleDown } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const FacilityTable: FunctionComponent<{
  data: FacilityConnection;
  refetch: () => any;
  handleLoadMore: () => void;
  headerButtons: JSX.Element[];
  facilityButtons: ((node: FacilityFieldsFragment) => JSX.Element)[];
}> = (props) => {
  const { data, refetch, handleLoadMore, headerButtons, facilityButtons } =
    props;

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          Facilities <Badge pill>{data.totalCount}</Badge>{' '}
          <ButtonGroup size="sm">
            <Button onClick={() => refetch()} variant="outline-secondary">
              <ArrowClockwise />
            </Button>
            {headerButtons.map((b) => b)}
          </ButtonGroup>
        </Card.Title>
        <Table hover size="sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.edges.map((e) => {
              const node = e.node as FacilityFieldsFragment;
              return (
                <tr key={node.id}>
                  <td>{node.id}</td>
                  <td>
                    <Link to={`/facilities/${node.id}`}>{node.name}</Link>
                  </td>
                  <td>
                    <ButtonGroup size="sm">
                      {facilityButtons.map((b) => b(node))}
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button
          variant="outline-secondary"
          disabled={!data.pageInfo.hasNextPage}
          onClick={handleLoadMore}
        >
          Load More <ChevronDoubleDown />
        </Button>
      </Card.Body>
    </Card>
  );
};

export default FacilityTable;
