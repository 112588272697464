import { gql } from '../../__generated__';

gql(`
fragment PersonFields on Person {
  id
  email
  personType
}`);

export const GET_PEOPLE = gql(`
query GetPeople($after: String, $first: Int) {
  getPeople(after: $after, first: $first) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        ...PersonFields
      }
    }
  }
}`);

export const CREATE_PERSON = gql(`
mutation CreateRoomsPerson($input: CreateRoomsPersonInput!) {
  createRoomsPerson(input: $input) {
    ...PersonFields
  }
}`);

export const GET_PERSON_FACILITIES = gql(`
query GetPersonFacilities($personId: String!, $first: Int, $after: String) {
  getPerson(personId: $personId) {
    ...PersonFields
    facilities(first: $first, after: $after) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...FacilityFields
        }
      }
    }
  }
}`);

export const UPDATE_PERSON_FACILITY = gql(`
mutation UpdatePersonFacility($personId: String!, $facilityId: String!, $action: ActionEnum!) {
  updatePersonFacility(personId: $personId, facilityId: $facilityId, action: $action)
}`);

export const DELETE_PERSON = gql(`
mutation DeletePerson($id: String!) {
    deletePerson(id: $id)
}`);
