import { gql } from '../../__generated__';

gql(`
fragment RoomFields on Room {
  id
  name
}`);

export const GET_FACILITY_ROOMS = gql(`
query GetFacilityRooms($id: String!, $first: Int, $after: String) {
  getFacility(id: $id) {
    ...FacilityFields

    rooms(first: $first, after: $after) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...RoomFields
        }
      }
    }
  }
}`);

export const GET_ROOMS = gql(`
query GetRooms($after: String, $first: Int, $facilityId: String!) {
  getRooms(after: $after, first: $first, facilityId: $facilityId) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        ...RoomFields
      }
    }
  }
}`);

export const UPSERT_ROOM = gql(`
mutation UpsertRoom($input: UpsertRoomInput!) {
  upsertRoom(input: $input) {
    ...RoomFields
  }
}`);

export const DELETE_ROOM = gql(`
mutation DeleteRoom($id: String!) {
  deleteRoom(id: $id)
}
`);
