import { gql } from '../../__generated__';

export const GET_REPRESENTATIVE_FACILITY_REQUESTS = gql(`
query GetRepresentativeFacilityRequests($first: Int, $after: String) {
    getRepresentativeFacilityRequests(first: $first, after: $after) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          person {
            ...PersonFields
          }
          facility {
            ...FacilityFields
          }
          status
        }
      }
    }
  }`);

export const RESPOND_TO_REP_FACILITY_REQUEST = gql(`
  mutation RespondToRepresentativeFacilityRequest($input: RepresentativeFacilityResponseInput!) {
    respondToRepresentativeFacilityRequest(input: $input) {
      person {
        ...PersonFields
      }
      facility {
        ...FacilityFields
      }
      status
    }
  }`);
