import { FunctionComponent } from 'react';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, Outlet } from 'react-router-dom';

const navLinkClass = ({ isActive }: { isActive: boolean }) =>
  isActive ? 'active nav-link' : 'nav-link';

const Root: FunctionComponent<{ signOut?: () => void }> = ({ signOut }) => {
  return (
    <>
      <Navbar bg="dark" data-bs-theme="dark">
        <Container>
          <Navbar.Brand>Replicare Admin</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink to={'/facilities'} className={navLinkClass}>
                Facilities
              </NavLink>
              <NavLink to={'/people'} className={navLinkClass}>
                People
              </NavLink>
              <NavLink to={'/facility-requests'} className={navLinkClass}>
                Facility Requests
              </NavLink>
            </Nav>
          </Navbar.Collapse>
          <Button variant="danger" onClick={signOut}>
            Sign out
          </Button>
        </Container>
      </Navbar>
      <Container>
        <Outlet />
      </Container>
    </>
  );
};

export default Root;
