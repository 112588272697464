import { FunctionComponent } from 'react';
import { PersonType } from '../__generated__/graphql';

import Badge from 'react-bootstrap/Badge';

const PersonTypeBadge: FunctionComponent<{ personType: PersonType }> = (
  props,
) => {
  switch (props.personType) {
    case PersonType.Representative:
      return <Badge bg="primary">Representative</Badge>;
    case PersonType.RoomsUser:
      return <Badge bg="success">Rooms user</Badge>;
  }
};

export default PersonTypeBadge;
