import React from 'react';
import '@aws-amplify/ui-react/styles.css';
import {
  WithAuthenticatorProps,
  withAuthenticator,
} from '@aws-amplify/ui-react';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Root from './routes/Root';
import FacilityPage from './pages/facilities/FacilityPage';
import FacilityDetails from './pages/facilities/FacilityDetails';
import PeoplePage from './pages/people/PeoplePage';
import PersonDetails from './pages/people/PersonDetails';
import FacilityRequest from './pages/facility-requests/FacilityRequests';
import SessionsPage from './pages/facilities/sessions/SessionsPage';

function App({ signOut }: WithAuthenticatorProps) {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root signOut={signOut} />,
      children: [
        { path: '/facilities', element: <FacilityPage /> },
        { path: '/facilities/:facilityId', element: <FacilityDetails /> },
        { path: '/facilities/:facilityId/sessions/:roomId', element: <SessionsPage />},
        { path: '/people', element: <PeoplePage /> },
        { path: '/people/:personId', element: <PersonDetails /> },
        { path: '/facility-requests', element: <FacilityRequest /> },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default withAuthenticator(App);
