import { FunctionComponent } from 'react';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

import { ArrowClockwise, ChevronDoubleDown } from 'react-bootstrap-icons';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_PERSON, DELETE_PERSON, GET_PEOPLE } from './queries';
import { PersonFieldsFragment } from '../../__generated__/graphql';
import UpsertPersonButton from './UpsertPersonButton';
import GraphQLErrors from '../../components/GraphQLErrors';
import { Link } from 'react-router-dom';
import DeleteButton from '../../components/DeleteButton';
import PersonTypeBadge from '../../components/PersonTypeBadge';

const PeoplePage: FunctionComponent = () => {
  const { data, error, loading, refetch, fetchMore } = useQuery(GET_PEOPLE);
  const [createPerson] = useMutation(CREATE_PERSON);
  const [deletePerson] = useMutation(DELETE_PERSON);

  const handleLoadMore = () => {
    if (data)
      fetchMore({
        variables: {
          after: data.getPeople.pageInfo.endCursor,
        },
      });
  };

  const handleDelete = (personId: string) => {
    deletePerson({ variables: { id: personId } }).then(() => refetch());
  };

  if (loading) return <h1>Loading...</h1>;
  if (error) return <GraphQLErrors error={error} />;
  if (!data) return <h1>No data</h1>;

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          People <Badge pill>{data.getPeople.totalCount}</Badge>{' '}
          <ButtonGroup size="sm">
            <Button onClick={() => refetch()} variant="outline-secondary">
              <ArrowClockwise />
            </Button>
            <UpsertPersonButton
              handleSave={(data) =>
                createPerson({ variables: { input: data } }).then(() =>
                  refetch(),
                )
              }
            />
          </ButtonGroup>
        </Card.Title>
        <Table hover size="sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.getPeople.edges.map((e) => {
              const node = e.node as PersonFieldsFragment;
              return (
                <tr key={node.id}>
                  <td>{node.id}</td>
                  <td>
                    <Link to={`/people/${node.id}`}>{node.email}</Link>
                  </td>
                  <td>
                    <PersonTypeBadge personType={node.personType} />
                  </td>
                  <td>
                    <DeleteButton
                      buttonSize="sm"
                      handleDelete={() => handleDelete(node.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button
          variant="outline-secondary"
          disabled={!data.getPeople.pageInfo.hasNextPage}
          onClick={handleLoadMore}
        >
          Load More <ChevronDoubleDown />
        </Button>
      </Card.Body>
    </Card>
  );
};

export default PeoplePage;
