import { FunctionComponent } from "react";
import { FieldError } from "react-hook-form";

const FormError: FunctionComponent<{ error?: FieldError }> = (props) => {
  if (props.error) {
    if (props.error.type === "required")
      return <span className="form-error">Required</span>;
  }
  return <></>;
};

export default FormError;
