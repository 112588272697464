import { useMutation, useQuery } from '@apollo/client';
import { FunctionComponent } from 'react';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import {
  GET_REPRESENTATIVE_FACILITY_REQUESTS,
  RESPOND_TO_REP_FACILITY_REQUEST,
} from './queries';
import GraphQLErrors from '../../components/GraphQLErrors';
import {
  ArrowClockwise,
  ChevronDoubleDown,
  HandThumbsDown,
  HandThumbsUp,
} from 'react-bootstrap-icons';
import {
  FacilityFieldsFragment,
  PersonFieldsFragment,
  RepresentativeFacilityResponses,
  RepresentativeFacilityStatus,
} from '../../__generated__/graphql';

const FacilityRequest: FunctionComponent = () => {
  const { data, error, loading, refetch, fetchMore } = useQuery(
    GET_REPRESENTATIVE_FACILITY_REQUESTS,
  );
  const [respondToRepFacilityRequest] = useMutation(
    RESPOND_TO_REP_FACILITY_REQUEST,
  );

  const handleLoadMore = () => {
    if (data)
      fetchMore({
        variables: {
          after: data.getRepresentativeFacilityRequests.pageInfo.endCursor,
        },
      });
  };

  if (loading) return <h1>Loading...</h1>;
  if (error) return <GraphQLErrors error={error} />;
  if (!data) return <h1>No data</h1>;

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          Facility Requests{' '}
          <Badge pill>
            {data.getRepresentativeFacilityRequests.totalCount}
          </Badge>{' '}
          <ButtonGroup size="sm">
            <Button onClick={() => refetch()} variant="outline-secondary">
              <ArrowClockwise />
            </Button>
          </ButtonGroup>
        </Card.Title>
        <Table hover size="sm">
          <thead>
            <tr>
              <th>Person</th>
              <th>Facility</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.getRepresentativeFacilityRequests.edges.map((e) => {
              const person = e.node.person as PersonFieldsFragment;
              const facility = e.node.facility as FacilityFieldsFragment;
              const {
                status,
                buttons,
              }: { status: JSX.Element; buttons: JSX.Element } = (() => {
                const approveButton = (
                  <Button
                    size="sm"
                    variant="success"
                    onClick={() =>
                      respondToRepFacilityRequest({
                        variables: {
                          input: {
                            facilityId: facility.id,
                            personId: person.id,
                            response: RepresentativeFacilityResponses.Approved,
                          },
                        },
                      }).then(() => refetch())
                    }
                  >
                    <HandThumbsUp />
                  </Button>
                );
                const rejectButton = (
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() =>
                      respondToRepFacilityRequest({
                        variables: {
                          input: {
                            facilityId: facility.id,
                            personId: person.id,
                            response: RepresentativeFacilityResponses.Rejected,
                          },
                        },
                      }).then(() => refetch())
                    }
                  >
                    <HandThumbsDown />
                  </Button>
                );
                switch (e.node.status) {
                  case RepresentativeFacilityStatus.PendingApproval:
                    return {
                      status: <Badge bg="info">Pending</Badge>,
                      buttons: (
                        <ButtonGroup size="sm">
                          {approveButton}
                          {rejectButton}
                        </ButtonGroup>
                      ),
                    };
                  case RepresentativeFacilityStatus.Approved:
                    return {
                      status: <Badge bg="success">Approved</Badge>,
                      buttons: rejectButton,
                    };
                  case RepresentativeFacilityStatus.Rejected:
                    return {
                      status: <Badge bg="danger">Rejected</Badge>,
                      buttons: approveButton,
                    };
                }
              })();

              return (
                <tr key={e.cursor}>
                  <td>{person.email}</td>
                  <td>{facility.name}</td>
                  <td>{status}</td>
                  <td>{buttons}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button
          variant="outline-secondary"
          disabled={
            !data.getRepresentativeFacilityRequests.pageInfo.hasNextPage
          }
          onClick={handleLoadMore}
        >
          Load More <ChevronDoubleDown />
        </Button>
      </Card.Body>
    </Card>
  );
};

export default FacilityRequest;
