import { FunctionComponent, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { Plus } from 'react-bootstrap-icons';
import { useLazyQuery } from '@apollo/client';
import { GET_FACILITIES } from '../facilities/queries';
import GraphQLErrors from '../../components/GraphQLErrors';
import FacilityTable from '../../components/FacilityTable';
import { FacilityConnection } from '../../__generated__/graphql';

const AddFacilityButton: FunctionComponent<{
  handleAdd: (facilityId: string) => void;
}> = (props) => {
  const [load, { loading, data, error, fetchMore }] =
    useLazyQuery(GET_FACILITIES);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    load();
  };

  const handleLoadMore = () => {
    if (data)
      fetchMore({
        variables: { after: data.getFacilities.pageInfo.endCursor },
      });
  };

  const handleAddFacility = (id: string) => props.handleAdd(id);

  return (
    <>
      <Button onClick={handleShow}>
        <Plus />
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Facility</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <h1>Loading...</h1>
          ) : error ? (
            <GraphQLErrors error={error} />
          ) : !data ? (
            'No data'
          ) : (
            <FacilityTable
              data={data.getFacilities as FacilityConnection}
              refetch={load}
              handleLoadMore={handleLoadMore}
              headerButtons={[]}
              facilityButtons={[
                (node) => (
                  <Button onClick={() => handleAddFacility(node.id)} key="add">
                    <Plus />
                  </Button>
                ),
              ]}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddFacilityButton;
